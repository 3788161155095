<template>
  <div class="progress">
    <div v-for="item in progress" :key="item.taskId" class="progress-item">
      <div>
        <div class="item-user">
          <div class="item-user-avatar">
            <template v-if="item.user && item.user.id != null">
              <avatar :show-name="false" :status="getStatus(item)" :name="item.user.name" :src="item.user.avatar"></avatar>
            </template>
            <template v-else>
              <span v-if="item.nodeType === 'APPROVAL'" class="iconfont icon-huiyi multi-user-avatar"></span>
              <span v-else class="el-icon-s-promotion multi-user-avatar"></span>
            </template>
          </div>
          <div :class="{'item-user-desc': true, 'subprocess': item.nodeType === 'SUBPROC'}" @click="showItemNode(item)">
            <div>
              {{ item.name }}
              <span v-if="item.nodeType === 'SUBPROC'" style="margin-left: 10px">
                <i class="el-icon-view"></i> 查看
              </span>
            </div>
            <div>{{ getDesc(item) }}
              <el-button type="text" v-if="item.nodeId == 'is_checked' && rows.processDefName == '券品调拨申请'" @click="toAdd(2)">详情</el-button>
              <el-button type="text" v-if="item.nodeId == 'is_checked' && (rows.processDefName == '销售礼券申请' || rows.processDefName == '免费礼券申请')" @click="getOrderInfoData">详情</el-button>
            </div>
          </div>
        </div>
        <div class="time">
          {{ getShortTime(item.finishTime, item) }}
        </div>
      </div>
      <div v-if="item.users && item.users.length > 0" class="multi-user">
        <div v-for="subItem in item.users" :key="subItem.taskId" class="">
          <div class="item-user-avatar">
            <avatar showY :size="35" :status="getStatus(subItem)" :name="subItem.user.name" :src="subItem.user.avatar" v-if="subItem.user != null"></avatar>
          </div>
        </div>
      </div>
      <div v-show="(item.comment && item.comment.length > 0) || item.signature" class="user-comment">
        <div v-if="item.signature" style="display: flex; align-items: center">
          <span><i class="el-icon-edit"></i> 签字：</span>
          <img :src="item.signature" style="width: 140px; height: 60px">
        </div>
        <div v-for="cmt, index in item.comment" :key="cmt.id" style="position: relative;">
          <!-- newUserId为null时，事件为同意、拒绝等评论内容 -->
          <div v-if="cmt != null && cmt.newUserId == null">
            <div class="user-comment-content" v-if="cmt.text != null || (cmt.attachments != null && cmt.attachments.length  > 0)">
              <div>{{ cmt.oldUser.name }}（{{ getAgree(item) }}）</div>
              <div class="user-comment-time">{{getShortTime(cmt.createTime)}}</div>
              <div class="text-comment">{{cmt.text}}</div>
              <div class="image-comment" v-show="cmt.attachments && cmt.attachments.length > 0">
                <el-image class="image" :src="img" v-for="(img, i) in filterImages(cmt.attachments)"
                          :key="img" :preview-src-list="filterImages(cmt.attachments)"></el-image>
              </div>
              <div class="file-comment">
                <ellipsis style="display: inline-block" class="file-item" type="primary" @click.native="download(file)"
                          :content="file.name" v-for="file in filterFiles(cmt.attachments)" :key="file.id">
                  <i slot="pre" class="el-icon-document"></i>
                </ellipsis>
              </div>
            </div>
          </div>
          <!-- newUserId不为null时，事件为转交、后加签等操作 -->
           <div v-if="cmt != null && cmt.newUserId != null">
            <div class="user-comment-content" v-if="cmt.text != null || (cmt.attachments != null && cmt.attachments.length  > 0)">
              <div>{{ cmt.oldUser.name }} {{ cmt.type == 'transfer' ? '转交' : cmt.type == 'afterAdd' ? '后加签' : '' }}给-> {{ cmt.newUser.name }}</div>
              <div class="user-comment-time">{{getShortTime(cmt.createTime)}}</div>
              <div class="text-comment">{{cmt.text}}</div>
              <div class="image-comment" v-show="cmt.attachments && cmt.attachments.length > 0">
                <el-image class="image" :src="img" v-for="(img, i) in filterImages(cmt.attachments)"
                          :key="img" :preview-src-list="filterImages(cmt.attachments)"></el-image>
              </div>
              <div class="file-comment">
                <ellipsis style="display: inline-block" class="file-item" type="primary" @click.native="download(file)"
                          :content="file.name" v-for="file in filterFiles(cmt.attachments)" :key="file.id">
                  <i slot="pre" class="el-icon-document"></i>
                </ellipsis>
              </div>
            </div>
           </div>
        </div>
      </div>
    </div>
    <div class="progress-item end-process">
      <div class="item-user-avatar node-icon">
        <span :class="[statusIcon, 'multi-user-avatar']"></span>
      </div>
      <div class="item-user-desc" style="font-size: 16px; line-height: 34px;">
        {{status}}
      </div>
    </div>
    <el-drawer :size="isMobile ? '100%': '500px'" append-to-body direction="rtl" title="子流程详情" :visible.sync="showSubProc">
      <instance-view v-if="showSubProc" :instance-id="subProcInst"></instance-view>
    </el-drawer>

    <el-dialog append-to-body title="详情" :visible.sync="PopupDialogFlag1" width="calc(98vw)" @close="PopupDialogFlag1 = false">
      <div style="padding: 0px 10px;">
        <p style="color:#666666;font-size: 15px;margin-top: -10px;padding-bottom: 10px;">
          <span>申请单ID：{{ rows.instanceId?rows.instanceId:"无" }}</span>
          <span style="padding-left: 12px;">审批类型：{{ rows.instanceId?"调拨申请":"直接调拨" }}</span>
        </p>
        <el-table ref="elTable" style="margin-top: 10px;" fit highlight-current-row resizable :data="confirmData">
          <el-table-column prop="brandName" label="券名称" show-overflow-tooltip />
          <el-table-column prop="categoryName" label="券类" min-width="120" show-overflow-tooltip />
          <el-table-column label="券介质" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.attributeType==1?'纸质券':scope.row.attributeType==2?'电子券':scope.row.attributeType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="券用途" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.useType==1?'销售':scope.row.useType==2?'免费':scope.row.useType==3?'工具':scope.row.useType }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="调拨数量" show-overflow-tooltip />
          <el-table-column prop="beginBn" label="开始编码" width="130" show-overflow-tooltip />
          <el-table-column prop="endBn" label="结束编码" width="130" show-overflow-tooltip />
          <el-table-column prop="brandPrice" label="面额" show-overflow-tooltip />
          <el-table-column prop="effectTime" label="指定生效日期" width="130" show-overflow-tooltip />
          <el-table-column label="有效类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.timeType==0?'有效天数':scope.row.timeType==1?'有效日期':scope.row.timeType==2?'以套明细为准':scope.row.timeType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="有效类型" show-overflow-tooltip width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.timeType == 0">{{ scope.row.timeEnd }} 天</span>
              <span v-if="scope.row.timeType == 1">{{ scope.row.timeEnd }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="PopupDialogFlag1 = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="详情" custom-class="dialogs" :close-on-click-modal="false" :visible.sync="saleCouponConfirm" width="calc(98vw)" @close="saleCouponConfirm = false">
      <div class="tables">
        <el-table ref="elTable" fit highlight-current-row resizable :data="tableDetailData">
          <el-table-column prop="categoryName" label="券类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brandName" label="券名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="sellPrice" label="单价" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{(scope.row.sellPrice/scope.row.count).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="数量"></el-table-column>
          <el-table-column prop="attributeType" label="券介质" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.attributeType==1">纸质券</span>
              <span v-else-if="scope.row.attributeType==2">电子券</span>
              <span v-else>{{ scope.row.attributeType }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="sellPrice" label="金额" show-overflow-tooltip></el-table-column>
          <el-table-column prop="category" label="开始编码-结束编码" width="210" show-overflow-tooltip>
            <template slot-scope="scope">
              <span slot="reference"
              >{{ scope.row.bnBegin }}--{{ scope.row.bnEnd }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="effectTime" label="生效日期" width="110" show-overflow-tooltip></el-table-column>
          <el-table-column prop="endTime" label="失效日期" width="130" show-overflow-tooltip></el-table-column>
          <el-table-column prop="buyName" label="客人姓名" width="110" show-overflow-tooltip></el-table-column>
          <el-table-column prop="buyPhone" label="客人手机号" width="130" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="saleCouponConfirm = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" custom-class="dialogs" :close-on-click-modal="false" :visible.sync="backSetting" width="calc(98vw)" @close="backSetting = false">
      <div style="padding: 0px 10px">
        <el-table style="margin-top: 10px" ref="elTable" fit highlight-current-row resizable :data="backDetailData">
          <el-table-column prop="createTime" label="退回时间" width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createByName" label="操作人" width="110" show-overflow-tooltip></el-table-column>
          <el-table-column prop="bnBegin" label="券编码" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brandName" label="券名称" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="categoryName" label="券类" width="200"></el-table-column>
          <!-- 单体券 -->
          <!-- 单体券 -->
          <el-table-column prop="attributeType" label="券介质" width="100">
            <template slot-scope="scope">
              <span style="font-size: 13px">{{
                  scope.row.attributeType == "1" ? "纸质" : "电子"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="buyName" label="客人姓名" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" prop="buyPhone" label="客人手机号" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <div class="dialog-footer" style="margin-top: 39px;margin-bottom: -15px;">
        <el-button size="small" @click="backSetting = false">取消</el-button>
      </div>
    </el-dialog>
    <Popup ref="popup" :table-data="confirmData" :PopupDialogFlag.sync="PopupDialogFlag"/>
    <BrandInfo ref="BrandInfo"></BrandInfo>
  </div>
</template>

<script>
import {getTaskResult} from "@/utils/ProcessUtil.js";
import BrandInfo from '../../../components/common/brandInfoComponent'
import Popup from '../../../components/common/changeUseLimit.vue'
import {getOrderInfo, getRefundOrderInfo, getOrderId, docMoveInfoSearch, getCommentUserType} from "@/api/process.js";
export default {
  name: "ProcessProgress",
  components: {InstanceView: () => import("./ProcessInstancePreview.vue"), Popup, BrandInfo},
  props: {
    progress: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    result: {
      required: true
    },
    status:{
      required: true,
      default: '未知'
    },
    rows: {
      required: true
    }
  },
  mounted() {
    this.progress.forEach(item => {
      if (item.nodeId != 'root') {
        item.comment.forEach(comment => {
          getCommentUserType(comment.user.id, comment.createTime, comment.taskId).then(res => {
            const r = res.data.split('_')
            comment.userType = r.length > 2 ? r[2] : '';
          })
        })
      }
    })
    this.$nextTick(() => {})
  },
  computed:{
    isMobile() {
      return window.screen.width < 450
    },
    filteredData() {
      return this.tableDetailsData.filter(item => item.isReturn === 1);
    },
    statusIcon() {
      switch (this.result){
        case 'RUNNING':
        case 'COMPLETE':
          return 'el-icon-more'
        case 'PASS':
          return 'el-icon-success'
        case 'CANCEL':
          return 'el-icon-circle-close'
        case 'REFUSE':
          return 'el-icon-error'
        case 'INVALID':
          return 'el-icon-remove'
      }
    },
  },
  data() {
    return {
      showSubProc: false,
      subProcInst: null,
      PopupDialogFlag: false,
      PopupDialogFlag1: false,
      saleCouponConfirm: false,
      freeCouponConfirm: false,
      backSetting: false,
      confirmData: [],
      tableDetailData: [],
      tableDetailsData: [],
      backDetailData: [],
      type: 0,
      bnStatus: [
        {name:'在库', status: 1},
        {name:'退库', status: -1},
        {name:'调拨', status: 2},
        {name:'退调拨', status: -2},
        {name:'销售', status: 3},
        {name:'退销售', status: -3},
        {name:'发放', status: 4},
        {name:'退发放', status: -4},
        {name:'转赠', status: 5},
        {name:'退转赠', status: -5},
        {name:'核销', status: 6},
        {name:'退核销', status: -6},
        {name:'强制核销', status: 7},
        {name:'退强制核销', status: -7},
        {name:'过期确收', status: 8},
        {name:'退过期确收', status: -8},
        {name:'作废', status: 9},
        {name:'退作废', status: -9},
        {name:'延期', status: 10},
        {name:'退延期', status: -10},
        {name:'修改时间', status: 11},
        {name:'变更使用限制', status: 12},
        {name:'变更券内容', status: 13},
        {name:'修改销售价格', status: 14},
        {name:'修改核销金额', status: 15},
        {name:'修改核销面额', status: 16},
        {name:'修改销售、发放客人', status: 17},
        {name:'核销日期', status: 18},
        {name:'核销单号', status: 19}
      ],
    }
  },
  methods: {
    getTaskResult,
    getChangeType(e) {
      for (var i = 0; i < this.bnStatus.length; i++) {
        if (this.bnStatus[i].status == e) {
          return this.bnStatus[i].name;
        }
      }
    },

    brandDetails(brandId){
      let that=this
      that.$nextTick(()=>{
        that.$refs.BrandInfo.opening(brandId);
      })
    },
    //详情
    getOrderInfoData() {
      getOrderId({instanceId: this.rows.instanceId, defName: this.rows.processDefName}).then(res => {
        if (this.rows.progress[this.rows.progress.length - 1].nodeId != 'to_back') {
          getOrderInfo({
            id: res.data.msg,
          }).then((response) => {
            this.saleCouponConfirm = true;
            this.tableDetailData = response.data.data;
          });
        } else {
          getRefundOrderInfo({
            id: res.data.msg,
          }).then((response) => {
          this.backSetting = true;
            this.backDetailData = response.data.data;
          });
        }
      })
    },
    toAdd(type) {
      // 拿instanceid去获取tkt_doc_move表id
      getOrderId({instanceId: this.rows.instanceId, defName: this.rows.processDefName}).then(res => {
        docMoveInfoSearch({
          id: res.data.msg
        }).then(res => {
          if (res.data.code == 200) {
            this.confirmData = res.data.data.moveDList
            this.PopupDialogFlag1 = true
            this.type = type
          }
        })
      })
    },
    backDetail() {
      getOrderId({instanceId: this.rows.instanceId, defName: this.rows.processDefName}).then(res => {
        getRefundOrderInfo({
          id: res.data.msg,
        }).then((response) => {
          this.backSetting = true;
          this.backDetailData = response.data.data;
        });
      })

    },
    filterImages(attachments){
      return (attachments || []).filter(f => f.isImage).map(f => {
        return this.$getRes(f.url)
      })
    },
    filterFiles(attachments){
      return (attachments || []).filter(f => !f.isImage).map(f => {
        return {...f, url: this.$getRes(f.url)}
      })
    },
    download(file) {
      window.open(`${this.$getRes(file.url)}?name=${file.name}`, '_blank')
    },
    getSize(size) {
      if (size > 1048576) {
        return (size / 1048576).toFixed(1) + 'MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(1) + 'KB'
      } else {
        return size + 'B'
      }
    },
    getShortTime(time, item) {
      if (time) {
        return time.substring(5, 16)
      } else if (item.nodeType == 'CHECK') {
        if (item.users || item.user.id){
          return '处理中'
        }
        if (item.candidateUser && item.candidateUser.length == 0) return '待派单'
        return '待接单'
      }
      return '处理中'
    },
    showItemNode(item){
      if (item.nodeType === 'SUBPROC'){
        this.subProcInst = item.taskId
        this.showSubProc = true
      }
    },
    getStatus(item){
      if (item.finishTime === null){
        return 'pending'
      }else if (item.nodeType === 'CC'){
        return 'cc'
      }else if (item.result === 'agree'){
        return 'success'
      }else if (item.result === 'refuse'){
        return 'error'
      }else if (item.result === 'comment'){
        return 'comment'
      }else if (item.result === 'transfer'){
        return 'transfer'
      }else if (item.result === 'recall'){
        return 'recall'
      }else if (item.nodeType === 'cancel'){
        return 'cancel'
      }else {
        return undefined
      }
    },
    getDesc(item){
      if (item.nodeType === 'ROOT'){
        return item.user.name
      }else if (item.nodeType === 'APPROVAL'){
        if (item.user){
          return `${item.user.name}（${this.getTaskResult(item).text}）`
        }
        let desc = (item.users || []).length + '人（'
        switch (item.approvalMode){
          case 'AND': return desc + '会签）'
          case 'OR': return desc + '或签）'
          case 'NEXT': return desc + '顺序会签）'
        }
      }else if (item.nodeType === 'CC'){
        if (item.user){
          return `抄送 ${item.user.name}`
        }
        return `抄送 ${item.users.length}人`
      }else if (item.nodeType === 'SUBPROC') {
        switch (item.result){
          case 'agree': return `${item.user.name} 发起的子流程（审批通过）`;
          case 'cancel': return `${item.user.name} 发起的子流程（被撤销）`;
          case 'refuse': return `${item.user.name} 发起的子流程（被驳回）`;
          default: return `${item.user.name} 发起的子流程（正在处理中）`;
        }
      }else if (item.result === 'comment') {
        return `${item.user.name}（添加了评论）`
      }else if (item.nodeType === 'CHECK') {
        if (item.result == 'refuse') return '申请被驳回'
        if (item.candidateUser) {
          if (item.candidateUser.length == 0 && item.user.id !== null && item.result !== null) return item.user.name + '（已处理）'
          if (item.user.id !== null && item.result == null) return item.user.name + '（处理中）'
          if (item.candidateUser.length == 0 && item.user.id === null) return '等待管理员派单'
          if (item.candidateUser.length > 0 && item.user.id === null) {
            let desc = item.candidateUser.length + '人（'
            for (let i = 0; i < item.candidateUser.length; i++) {
              desc += item.candidateUser[i].userName
              if (i < item.candidateUser.length - 1){
                desc += '、'
              }
            }
            return desc + '）'
          }
        }
      }else {
        return item.user.name
      }
    },
    getAgree(item){
      if (item.result === 'agree'){
        return '已同意'
      }else if (item.result === 'refuse'){
        return '已拒绝'
      }else if (item.result === 'recall'){
        return '已退回'
      }else if (!item.result && item.finishTime){
        return '已取消'
      }else if (!item.result && item.nodeType == 'CHECK'){
        return '待接单'
      }else {
        return '处理中'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.progress {
  position: relative;
}

.subprocess{
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: @theme-aside-bgc;
    color: @theme-primary;
  }
}

.progress::after {
  content: '';
  position: absolute;
  width: 4px;
  top: 0;
  left: 18px;
  color: #e4e4e4;
  background: #e4e4e4;
  height: 100%;
}

.end-process{
  .node-icon{
    span{
      font-size: 30px;
    }
    .el-icon-success{
      color: @theme-success !important;
      background: white !important;
    }
    .el-icon-error{
      color: @theme-danger !important;
      background: white !important;
    }
    .el-icon-circle-close{
      color: #979797 !important;
      background: white !important;
    }
    .el-icon-more{
      color: #b0b0b0 !important;
      background: #e4e4e4 !important;
    }
    .el-icon-remove{
      color: @theme-warning !important;
      background-color: white !important;
    }
  }
}

.progress-item {
  z-index: 1;
  margin-bottom: 30px;
  position: relative;

  .item-user {
    & > div {
      display: inline-block;
    }
  }

  .user-comment{
    margin-left: 60px;
    font-size: 14px;
    position: relative;
    padding-top: 5px;
    .user-comment-user{
      display: flex;
      align-items: center;
    }
    /deep/ .a-img span{
      font-size: 12px;
    }
    .user-comment-time{
      position: absolute;
      right: 8px;
      top: 7px;
      color: #8c8c8c;
      font-size: small;
    }
    .user-comment-content{
      margin: 5px 0;
      background: #eeeeee;
      padding: 5px 20px;
      border-radius: 0 5px 5px 5px;

      /deep/ .image-comment{
        padding: 5px 0;
        .image{
          height: 60px;
          width: 60px;
          padding: 5px;
        }
        img{
          border-radius: 5px;
        }
      }
      .file-comment{
        .file-item {
          color: @theme-primary;
          cursor: pointer;
        }
      }
    }
  }

  .item-user-avatar {
    background: white;
    position: relative;
    padding: 4px 0;

    i {
      border-radius: 50%;
      font-size: 15px;
      color: white;
      border: 2px solid white;
      position: absolute;
      padding: 0px;
      right: -10px;
      top: 25px;
    }

    .el-icon-s-promotion{
      padding: 1px;
      font-size: 12px;
    }
    .el-icon-more {
      color: white;
      font-size: 6px !important;
    }



    .multi-user-avatar{
      display: flex;
      height: 40px;
      width: 40px;
      background: @theme-primary;
      color: white;
      font-size: 28px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
  }

  .item-user-desc {
    position: absolute;
    left: 60px;
    top: 5px;
    font-size: 14px;

    div:nth-child(1) {
      color: #8c8c8c;
    }

    div:nth-child(2) {
      font-size: 15px;
      color: black;
    }
  }
}

.time {
  font-size: 15px;
  color: #8a8a8a;
  position: absolute;
  right: 0;
  top: 0;
}

.multi-user{
  display: grid;
  margin-left: 50px;
  grid-template-columns: repeat(7, 60px);
  .item-user-avatar{
    i{
      right: 2px;
    }
  }
}
</style>
