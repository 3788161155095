import request from './request.js'

//获取用户待办
export function getUserTodoList(params) {
  return request({
    url: `wflow/process/task/todoList`,
    method: 'get',
    params: params
  })
}

//获取用户待办---王
export function getUserTodoListFilter(params) {
  return request({
    //url: `/wflow/process/task/pageFilter`,
    url: `/wflow/process/task/todoList`,
    method: 'get',
    params: params
  })
}
//获取用户认领
export function getCandidateListFilter(params) {
  return request({
    url: `/wflow/process/task/candidateList`,
    method: 'get',
    params: params
  })
}
//获取用户发起的实例
export function getUserSubmittedList(params) {
  return request({
    url: `wflow/process/mySubmitted`,
    method: 'get',
    params: params
  })
}
//获取用户发起的实例--王
export function getUserSubmittedListFilter(params) {
  return request({
    url: `wflow/process/mySubmitted`,
    method: 'get',
    params: params
  })
}
//审批类型
export function mySubmitTypeList(params) {
  return request({
    //url: `wflow/process/task/getModelListByTenantId`,
    url: `wflow/model/group/list`,
    method: 'get',
    params: params
  })
}
//获取所有发起的实例
export function getSubmittedList(params) {
  return request({
    url: `wflow/process/submittedList`,
    method: 'get',
    params: params
  })
}
//获取发起的指定实例
export function getAssignedList(instanceId) {
  return request({
    url: `wflow/process/assignedList`,
    method: 'get',
    params: {
      instanceId: instanceId
    }
  })
}

//获取我已处理的所有审批实例
export function getIdoList(params) {
  return request({
    url: `wflow/process/task/idoList`,
    method: 'get',
    params: params
  })
}


//获取我已处理的所有审批实例---王
export function getIdoListFilter(params) {
  return request({
    url: `wflow/process/task/idoList`,
    method: 'get',
    params: params
  })
}

//查询流程进度及表单
export function getFormAndProcessProgress(instanceId, nodeId) {
  return request({
    url: `wflow/process/progress/${instanceId}/${nodeId}`,
    method: 'get'
  })
}

//处理任务
export function approvalTask(params) {
  return request({
    url: `wflow/process/task/handler`,
    method: 'post',
    data: params
  })
}

//处理任务-----批量
export function approvalTaskList(params) {
  return request({
    url: `wflow/process/task/handlerList`,
    method: 'post',
    data: params
  })
}
//获取流程实例表单数据
export function getInstanceFormData(instanceId) {
  return request({
    url: `wflow/process/form/data/by/${instanceId}`,
    method: 'get'
  })
}

//获取可回退的节点
export function getEnableRecallNodes(instanceId, taskId) {
  return request({
    url: `wflow/process/task/recall/nodes`,
    method: 'get',
    params: { instanceId: instanceId, taskId: taskId }
  })
}

export function getTaskNodeSettings(taskId) {
  return request({
    url: `wflow/process/task/settings/${taskId}`,
    method: 'get'
  })
}

// 获取抄送我的流程---王
export function getCcMeListFilter(params) {
  return request({
    url: `wflow/process/ccMe`,
    method: 'get',
    params: params
  })
}

export default {
  getUserTodoList, getUserTodoListFilter, getUserSubmittedList, getSubmittedList, getUserSubmittedListFilter, mySubmitTypeList,
  getFormAndProcessProgress, approvalTask, getInstanceFormData, getCcMeListFilter,
  getEnableRecallNodes, getIdoList, getIdoListFilter, getTaskNodeSettings, getCandidateListFilter, getAssignedList
}
