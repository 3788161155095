import request from '@/api/request.js'


// 查询表单组
export function getFormGroups(param) {
  return request({
    url: 'admin/form/group',
    method: 'get',
    params: param
  })
}

// 表单排序
export function groupItemsSort(param) {
  return request({
    url: 'admin/form/group/sort',
    method: 'put',
    data: param
  })
}

// 更新表单组
export function updateGroup(param, method) {
  return request({
    url: 'admin/form/group',
    method: method,
    params: param
  })
}

// 获取表单分组
export function getGroup() {
  return request({
    url: 'admin/form/group/list',
    method: 'get'
  })
}

// 更新表单
export function updateForm(param) {
  return request({
    url: 'admin/form',
    method: 'put',
    params: param
  })
}

export function createForm(param){
  return request({
    url: 'admin/form',
    method: 'post',
    data: param
  })
}

// 查询表单详情
export function getFormDetail(id) {
  return request({
    url: 'admin/form/detail/' + id,
    method: 'get'
  })
}

// 更新表单详情
export function updateFormDetail(param) {
  return request({
    url: 'admin/form/detail',
    method: 'put',
    data: param
  })
}

// 自定义组件：支付方式
export function getPayWay() {
  return request({
    url: 'form/custom/payway',
    method: 'get'
  })
}

// 自定义组件：单位查询
export function getPart() {
  return request({
    url: 'form/custom/part',
    method: 'get'
  })
}

// 自定义组件：区域查询
export function getArea() {
  return request({
    url: 'form/custom/area',
    method: 'get'
  })
}

// 自定义组件：故障类型查询
export function getFault() {
  return request({
    url: 'form/custom/fault',
    method: 'get'
  })
}

export default {
  getFormGroups, groupItemsSort, createForm, getFormDetail,
  updateGroup, getGroup, updateForm, updateFormDetail, 
  getPayWay, getPart, getArea, getFault
}
